@import 'public/less/variables.less';

.staging {
  background: #643a44;
  box-shadow: 0 -1px 15px #252833;
  color: #b8a5a5;
  z-index: 5;
  position: relative;

  .form-control {
    &:disabled {
      background-color: rgba(64, 36, 43, 0.75);
    }
  }

  textarea.commit-body {
    resize: vertical;
  }

  .arrow {
    border-top-color: #643a44;
    left: (@log-width-small + 45px);
    bottom: -30px;
  }

  .commitnpush.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .file-area {
    position: relative;
  }

  .validationError {
    display: none;
    color: #d6542d;
    padding: 0.25em;
  }

  &:hover .validationError {
    display: inline-block;
  }

  .diffContainer {
    margin-top: 0;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.1);
  }

  .discard {
    background: transparent;
    color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    &:focus,
    &:hover {
      background: #000000;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .ignore {
    background: transparent;
    color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    font-weight: bold;

    &:focus,
    &:hover {
      background: #5555ff;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .patch {
    background: #279124;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    font-weight: bold;

    &:focus,
    &:hover {
      background: #279124;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .d2h-code-line-prefix input[type='checkbox'] {
    margin: 0;
    margin-right: -6px;
    vertical-align: sub;
  }

  .files {
    position: relative;

    .file {
      padding: 0.3em;

      &.showingDiffs {
        .name {
          background: rgba(255, 255, 255, 0.1);
          color: #000000;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .checkmark {
        span {
          top: 5px;
        }
      }

      .name {
        background: transparent;
        font-size: 1.3em;
        cursor: pointer;
        padding: 3px;
        border: 0;
        border-radius: 3px;
        color: rgba(255, 255, 255, 0.8);
      }

      .new,
      .deleted,
      .conflict,
      .markresolved,
      .launchmergetool {
        padding: 3px;
        padding-left: 5px;
        padding-right: 5px;
      }

      .new,
      .additions {
        color: #949494;
        vertical-align: middle;
      }

      .deleted,
      .deletions {
        color: #7b7b7b;
        vertical-align: middle;
      }

      .conflict {
        color: #db12c0;

        .explanation {
          display: none;
        }

        &:hover {
          .explanation {
            display: inline;
          }

          .temporary {
            display: none;
          }
        }
      }

      .markresolved {
        color: #db12c0;
        cursor: pointer;

        .explanation {
          display: none;
        }

        &:hover {
          background: #a445ed;
          color: #000000;
          border-radius: 3px;

          .explanation {
            display: inline;
          }
        }
      }

      .launchmergetool {
        color: #db55ff;
        cursor: pointer;

        .explanation {
          display: none;
        }

        &:hover {
          background: #a477ff;
          color: #000000;
          border-radius: 3px;

          .explanation {
            display: inline;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .staging {
    .arrow {
      left: (@log-width-large + 45px);
    }
  }
}

.commit-message-title-counter {
  right: 20px;
  position: absolute;
}

.amend-button {
  padding: 0;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.checkmark {
  color: #ffffff;
  display: inline-block;
  opacity: 0.3;
  cursor: pointer;

  &.checked {
    opacity: 0.8;
  }
}
